<template>
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ cName }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="商户号" prop="mchId">
        <a-input v-model="form.mchId" placeholder="请输入"/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { payMerchantAdd, payMerchantEdit } from '@/api/pages/company'

export default {
  name: 'CreateForm',
  props: {
    typeOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      cName: '',
      submitLoading: false,
      // 表单
      form: {
        id: 0,
        companyId: '',
        mchId: '',
        mchKey: '',
        keyPath: ''
      },
      open: false,
      rules: {
        mchId: [{ required: true, message: '商户号不能为空', trigger: 'blur' }]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
    },
    /** 修改按钮操作 */
    handleUpdate (row) {
      this.cName = row.name
      this.form = {
        id: row.mid ? row.mid : 0,
        companyId: row.id,
        mchId: row.mchId,
        mchKey: '',
        keyPath: ''
      }
      this.open = true
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log(this.form)
      var that = this
      that.$refs.form.validate(valid => {
        if (valid) {
          that.$confirm({
            title: '提示',
            content: `确认变更 ${this.cName} 商户号?`,
            onOk () {
              that.submitLoading = true
              if (that.form.id) {
                payMerchantEdit(that.form).then(response => {
                  that.$message.success(
                    '修改成功',
                    3
                  )
                  that.open = false
                  that.$emit('ok')
                }).finally(() => {
                  that.submitLoading = false
                })
              } else {
                payMerchantAdd(that.form).then(response => {
                  that.$message.success(
                    '新增成功',
                    3
                  )
                  that.open = false
                  that.$emit('ok')
                }).finally(() => {
                  that.submitLoading = false
                })
              }
            },
            onCancel () {}
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
