import request from '@/utils/request'

// 公司 列表
export function companyList(query) {
  return request({
    url: '/wisdom/wisdom-company/list',
    method: 'get',
    params: query
  })
}

// 公司 分页
export function companyPage(query) {
  return request({
    url: '/wisdom/wisdom-company/page',
    method: 'get',
    params: query
  })
}

// 公司 详情
export function companyInfo(query) {
  return request({
    url: '/wisdom/wisdom-company/detail',
    method: 'get',
    params: query
  })
}

// 公司 新增
export function companyAdd(data) {
  return request({
    url: '/wisdom/wisdom-company/add',
    method: 'post',
    data
  })
}

// 公司 编辑
export function companyEdit(data) {
  return request({
    url: '/wisdom/wisdom-company/edit',
    method: 'post',
    data
  })
}

// 公司 删除
export function companyDel(data) {
  return request({
    url: '/wisdom/wisdom-company/delete',
    method: 'post',
    data
  })
}

// 商户号 详情
export function payMerchantInfo(query) {
  return request({
    url: '/wisdom/wisdom-pay-merchant/detail',
    method: 'get',
    params: query
  })
}

// 商户号 新增
export function payMerchantAdd(data) {
  return request({
    url: '/wisdom/wisdom-pay-merchant/add',
    method: 'post',
    data
  })
}

// 商户号 编辑
export function payMerchantEdit(data) {
  return request({
    url: '/wisdom/wisdom-pay-merchant/edit',
    method: 'post',
    data
  })
}
